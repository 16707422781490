import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectors } from '../modules/auth/store'
import { useRouter } from 'next/router'
import { Disclosure } from '@headlessui/react'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import {
  Bars3Icon,
  BookOpenIcon,
  XMarkIcon,
  ArrowLeftIcon,
  ArrowRightIcon as ArrowRightIcon24,
} from '@heroicons/react/24/outline'
import { MocharyFullLogoIcon } from '@mm/company-ui-icons/src'
import CoachingWays1Graphic from '../modules/marketing/CoachingWays1'
import CoachingWays2Graphic from '../modules/marketing/CoachingWays2'
import CoachingWays3Graphic from '../modules/marketing/CoachingWays3'

const CURRICULUM_LINK = '/learn'
const CAREERS_LINK = '/careers'
const LOGIN_LINK = '/login'
const CONTACT_LINK = 'https://mocharymethod.typeform.com/interest'
const BLOG_LINK = 'https://beta.mocharymethod.com/blog'
const COACHES_LINK = '#coaches'

function Section({ children, className, bg }: { children: React.ReactNode; className?: string; bg?: string }) {
  return (
    <div className={bg}>
      <div className={`flex flex-col mx-auto max-w-7xl px-4 sm:px-8 py-16 sm:py-24 gap-12 sm:gap-16 ${className}`}>
        {children}
      </div>
    </div>
  )
}

function NavLink(props: any) {
  // React.HTMLProps<HTMLAnchorElement>
  return (
    <a
      {...props}
      className="rounded-md px-3 py-2 text-base font-semibold text-gray-500 hover:bg-primary-100/50 hover:text-gray-700"
    >
      {props.children}
    </a>
  )
}

function NavButton(props: any) {
  // React.HTMLProps<HTMLButtonElement>
  return (
    <a
      {...props}
      role="button"
      className="relative inline-flex items-center text-base font-semibold rounded-md border border-transparent bg-primary-600 px-4 py-2 text-white hover:text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 focus:ring-offset-white"
    >
      {props.children}
    </a>
  )
}

function MobileNavMenuButton(props: any) {
  // React.HTMLProps<HTMLAnchorElement>
  return (
    <Disclosure.Button
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      as={'a' as any}
      {...props}
      className="block rounded-md px-3 py-2 text-base font-medium text-gray-600 hover:bg-primary-600 hover:text-white"
    >
      {props.children}
    </Disclosure.Button>
  )
}

function Nav() {
  return (
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0 text-primary-600 h-[30px]">
                  <MocharyFullLogoIcon width={100} height={30} color="currentColor" className="!m-0 !mt-[2px]" />
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4">
                    <NavLink href="/">Home</NavLink>
                    <NavLink href={COACHES_LINK}>Coaching</NavLink>
                    <NavLink href={BLOG_LINK}>Blog</NavLink>
                    <NavLink href={CAREERS_LINK}>Careers</NavLink>
                  </div>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  <NavButton href={LOGIN_LINK}>Login</NavButton>
                </div>
              </div>
              <div className="-mr-2 flex sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-600 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              <MobileNavMenuButton href="/">Home</MobileNavMenuButton>
              <MobileNavMenuButton href={COACHES_LINK}>Coaching</MobileNavMenuButton>
              <MobileNavMenuButton href={BLOG_LINK}>Blog</MobileNavMenuButton>
              <MobileNavMenuButton href={CAREERS_LINK}>Careers</MobileNavMenuButton>
            </div>
            <div className="border-t border-gray-700 p-2">
              <MobileNavMenuButton href={LOGIN_LINK}>Login</MobileNavMenuButton>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

function CoachTeamMember({ imageHref, name, title, bio, profileHref, emailHref, linkedinHref, twitterHref }: any) {
  return (
    <div className="flex flex-col gap-6">
      <div className="aspect-video sm:aspect-square relative">
        <Image
          src={imageHref}
          alt={`Image of ${name}, ${title} at Mochary Method`}
          layout="fill"
          objectFit="cover"
          className="bg-primary-100"
        />
      </div>

      <div className="flex flex-col gap-4 flex-1">
        <a href={profileHref} target="_blank" rel="noopener noreferrer">
          <h5 className="text-xl font-semibold mb-1">{name}</h5>
          <div className="text-lg text-primary-600">{title}</div>
        </a>

        <p className="text-base text-gray-500 flex-1 mb-0">{bio}</p>
      </div>

      <div className="flex flex-row justify-between">
        <a
          href={profileHref}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center rounded-md font-semibold border border-transparent bg-primary-100 px-3 py-2 text-sm leading-4 text-primary-600 hover:text-primary-700 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-primary-200 focus:ring-offset-2"
        >
          View Profile
        </a>

        <div className="flex flex-row gap-4 items-center">
          {emailHref ? (
            <a href={emailHref} target="_blank" rel="noopener noreferrer">
              <svg
                className="text-gray-400"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.5 18L14.8571 12M9.14286 12L2.50003 18M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
                  stroke="currentCOlor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          ) : null}

          <a href={twitterHref} target="_blank" rel="noopener noreferrer">
            <svg
              className="text-gray-400"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.55016 21.75C16.6045 21.75 21.5583 14.2468 21.5583 7.74192C21.5583 7.53098 21.5536 7.31536 21.5442 7.10442C22.5079 6.40752 23.3395 5.54432 24 4.55536C23.1025 4.95466 22.1496 5.21544 21.1739 5.3288C22.2013 4.71297 22.9705 3.74553 23.3391 2.60583C22.3726 3.17862 21.3156 3.58267 20.2134 3.80067C19.4708 3.01162 18.489 2.48918 17.4197 2.31411C16.3504 2.13905 15.2532 2.32111 14.2977 2.83216C13.3423 3.3432 12.5818 4.15477 12.1338 5.14137C11.6859 6.12798 11.5754 7.23468 11.8195 8.29036C9.86249 8.19215 7.94794 7.68377 6.19998 6.79816C4.45203 5.91255 2.90969 4.6695 1.67297 3.14958C1.0444 4.2333 0.852057 5.51571 1.13503 6.73615C1.418 7.9566 2.15506 9.02351 3.19641 9.72005C2.41463 9.69523 1.64998 9.48474 0.965625 9.10598V9.16692C0.964925 10.3042 1.3581 11.4066 2.07831 12.2868C2.79852 13.167 3.80132 13.7706 4.91625 13.995C4.19206 14.1932 3.43198 14.2221 2.69484 14.0794C3.00945 15.0575 3.62157 15.913 4.44577 16.5264C5.26997 17.1398 6.26512 17.4807 7.29234 17.5013C5.54842 18.8712 3.39417 19.6142 1.17656 19.6107C0.783287 19.6101 0.390399 19.586 0 19.5385C2.25286 20.9838 4.87353 21.7514 7.55016 21.75Z"
                fill="currentColor"
              />
            </svg>
          </a>
          <a href={linkedinHref} target="_blank" rel="noopener noreferrer">
            <svg
              className="text-gray-400"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_173_49342)">
                <path
                  d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_173_49342">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </div>
  )
}

function Carousel() {
  const ref = React.useRef<HTMLUListElement>(null)
  const items = [
    {
      id: '1',
      imageHref: '/home/testimonials/Sam Altman.jpeg',
      quote:
        'Matt’s coaching has brought me clarity, focus, organization, less stress, higher performance (me and the team). I have always been skeptical of coaches but I think he can 10x the output of a lot of people and I hope he does!',
      name: 'Sam Altman',
      title: 'CEO, OpenAI',
    },
    {
      id: '2',
      imageHref: '/home/testimonials/Brian armstrong.jpeg',
      quote: `Matt has demystified growing a company by breaking it down into simple steps. These are the essentials that every company should implement to operate more effectively.`,
      name: 'Brian Armstrong',
      title: 'CEO, Coinbase',
    },
    {
      id: '3',
      imageHref: '/home/testimonials/Max Rhodes.webp',
      quote: `Scaling a company is really hard, especially if you've never done it before. I’m constantly looking for resources to help me figure it out. Matt and the materials he’s created are among the most impactful resources I’ve found for figuring out how to scale a startup. The tools, systems and practices he has developed are immediately applicable and have helped me become meaningfully better as a CEO.`,
      name: 'Max Rhodes',
      title: 'CEO, Faire',
    },
    {
      id: '4',
      imageHref: '/home/testimonials/Pat Grady.webp',
      quote: `The value of Matt’s coaching to me has been:
      \n1: effective mechanisms for prioritizing things that are important over things that are urgent
      \n2: ability to think through the things that are important and distill them down into actionable steps
      \n3: more confidence in myself to get important things done and to grow into being a better leader over time
      \n4: as a result of that confidence, a generally more optimistic/upbeat/happy cadence at work
      \n5: less stress`,
      name: 'Pat Grady',
      title: 'Partner, Sequoia Capital',
    },
    {
      id: '5',
      imageHref: '/home/testimonials/Ryan Petersen.webp',
      quote: `Matt has developed a repeatable set of operating principles that high growth companies can use to improve their businesses as they scale. By implementing his models at Flexport, we've improved our processes for strategic planning, running meetings, making decisions, sharing information, and resolving conflicts. I'm grateful for Matt's hard work to help us reach our full potential.`,
      name: 'Ryan Petersen',
      title: 'Founder, Flexport',
    },
    {
      id: '6',
      imageHref: '/home/testimonials/Stephen smith.webp',
      quote: `I don’t think I could have made it through my Series A raise without Quinn. He helped me develop techniques to quickly destress and persevere through this grueling fundraising round. I believe about 80-90% of people (including myself) would have been burned out without his advice. On the emotional side, he also helped me calm my nerves and give me strategies so I didn’t lash out at a board member, which honestly could have ended my career. The big picture is when I needed help the most, Quinn put me in the right mindset. I learned how to detach from work when I was away from the office, feel less overworked, and have a greater peace of mind. I’m very grateful for the work we did together.`,
      name: 'Stephen Smith',
      title: 'CEO, nOCD',
    },
    {
      id: '7',
      imageHref: '/home/testimonials/Steve Huffman.webp',
      quote: `Matt is an incredible coach. He transformed the way I work, and the way I think about work literally overnight. There are a couple of dimensions to Matt’s effectiveness:
            I would wholeheartedly recommend Matt to anyone who wants to learn to be a more effective leader, have better relationships, and carry less stress while also being more productive.`,
      // quote: `Matt is an incredible coach. He transformed the way I work, and the way I think about work literally overnight. There are a couple of dimensions to Matt’s effectiveness:
      // First, his positivity is contagious. I leave Matt’s sessions energized and excited about work, and his demeanor and mannerisms have rubbed off on me, hopefully helping me provide the same for my team.
      // Second, the structure and discipline of our meetings make our time together efficient and effective, and again, the habits he’s teaching me are trickling into my team. The predictability of consistency of our meetings means I can even simulate a 1:1 with Matt just by myself!
      // Third, Matt’s has helped me re-calibrate how much stress I should tolerate. The result is that I’m less stressed while being more productive.
      // Finally, Matt has a playbook for how to handle many common situations. The challenges I face often aren’t unique to me or Reddit, and having specific tactical guidance for how to handle them means I don’t have to learn every lesson the hard way.
      // I would wholeheartedly recommend Matt to anyone who wants to learn to be a more effective leader, have better relationships, and carry less stress while also being more productive.
      // `,
      name: 'Steve Huffman',
      title: 'CEO, Reddit',
    },
    {
      id: '8',
      imageHref: '/home/testimonials/Peter Fenton.jpeg',
      quote: `Matt’s coaching has brought me:
      A good-hygiene framework to consciously prioritize what really matters above all else  making commitments against those priorities, and a way to really be honest about follow through 
      Higher levels of awareness and trust not just here but in all the work I do
      A desire to help others go on a similar positive journey 
      A friend!`,
      name: 'Peter Fenton',
      title: 'GP, Benchmark',
    },
    {
      id: '9',
      imageHref: '/home/testimonials/Henrique-Dubugras.webp',
      quote: `Matt has made an enormous difference on me, and by extension, to the entire team at Brex. I am grateful for the time he dedicates to helping me solve my own problems, bringing incredible focus, empathy, and bias for action to our coaching sessions. The structure of our time is highly customized to my leadership style, and I am motivated by the early results. Specifically, my level of accountability and strategy for delegating have improved enormously. I wish that more people, including my executives and rising leadership team, could have a resource as dedicated and razor-sharp as Matt to counsel them through the good and bad times of growing a business.`,
      name: 'Henrique Dubugras',
      title: 'Founder and Co-CEO at Brex',
    },
    {
      id: '10',
      imageHref: '/home/testimonials/Luqman shantal.jpeg',
      quote: `The Mochary Method is not your typical coaching program.
      It’s run by expert business coaches, not salespeople with scripts, and is designed to bring about a transformational experience.`,
      name: 'Luqman Shantal',
      title: 'CEO, Makman Technology Consulting',
    },
    {
      id: '11',
      imageHref: '/home/testimonials/Levi Bucsis.jpeg',
      quote: `Alexis created a connection with me almost immediately, which laid the foundation for us to discuss the vulnerable and challenging aspects of running a business. With his own background as a CEO and his empathic qualities, I easily felt that my thoughts and feelings were understood. He was able to use his background and the Mochary Method to provide me with useful, actionable insights into how to move forward.`,
      name: 'Levi Bucsis',
      title: 'CEO, Momentum Dashboard',
    },
    {
      id: '12',
      imageHref: '/home/testimonials/Stephanie Ping.png',
      quote: `Celine is always able to distill a situation and identify the crux of the problem very strategically. She is also able to guide me to an innovative, and oftentimes non-obvious solution. Merely talking to her always (generates) an "Ah ha" moment and I feel like Celine "gets me.`,
      name: 'Stephanie Ping',
      title: 'CEO, WORQ',
    },
    {
      id: '13',
      imageHref: '/home/testimonials/Justin Kan.webp',
      quote: `Matt’s coaching has changed my life at work and at home. I am able to better name and communicate my emotions, which has allowed me to drive towards underlying interpersonal issues faster and more deeply. I also have been able to have more difficult conversations and get my exec team much more aligned. These may sound like very simple things, but they have been incredibly powerful techniques to drive positive change in a very short period of time.
      I wish there were 1,000 Matts to coach every CEO of every startup. I shudder to think about how much further I could have gone earlier if there was someone like Matt in my life as a coach in earlier companies I built. He is a positive, emotionally aware, and loving person. I love spending time with Matt, and look forward to seeing him every time we meet.`,
      name: 'Justin Kan',
      title: 'Former CEO, Atrium',
    },
    // {
    //   id: '14',
    //   imageHref: '/home/testimonials/Justin Kan.webp',
    //   quote: `Faith is an intuitive and powerful coach, who brings wisdom, guidance and great energy to her clients. In my executive coaching sessions, she guided me to get outside of confusion, and quite honestly, some panic, and led me to the tranquil waters of peace, calm, and an effective strategy that worked immediately. This helped me shift my mindset and create sustainable behaviors, mindsets and strategies that continue to serve me personally and professionally. She's an extraordinary individual and I highly recommend her!`,
    //   name: 'Jacqui Long',
    //   title: 'Co-Founder, Instant Nonprofit',
    // },
    {
      id: '15',
      imageHref: '/home/testimonials/Max Tremaine.png',
      quote: `Coaching with Faith has been like cooking with gas for adopting the Mochary Method. My team has found the MM curriculum empowering while prioritizing and coordinating, which has aligned us toward our most important goals. Faith’s coaching has aided this process considerably, but it has also made me a more thoughtful and eloquent leader. Start early, you will be happy.`,
      name: 'Max Tremaine',
      title: 'CEO, Sherpa',
    },
  ]
  const [lastScrolled, setLastScrolled] = useState<number | null>(null)
  const [activeItem, setActiveItem] = useState(0)

  const handleIncrement = () => {
    const newIndex = activeItem + 1 > items.length - 1 ? 0 : activeItem + 1
    setActiveItem(newIndex)
  }
  const handleDecrement = () => {
    const newIndex = activeItem - 1 < 0 ? items.length - 1 : activeItem - 1
    setActiveItem(newIndex)
  }

  useEffect(() => {
    if (ref.current) {
      const slide = ref.current.querySelector(`#slide-${items[activeItem].id}`) as HTMLElement

      if (lastScrolled !== null) {
        slide.scrollIntoView({ block: 'center' })
      }

      setLastScrolled(activeItem)
    }
  }, [activeItem])

  return (
    <div>
      <ul className="snap-x snap-mandatory relative w-full flex gap-6 overflow-x-hidden" ref={ref}>
        {items.map((item) => {
          return (
            <li
              key={item.id}
              className={`snap-center shrink-0 w-full flex flex-col sm:grid grid-cols-1 sm:grid-cols-3 gap-10 sm:gap-32`}
              id={`slide-${item.id}`}
            >
              <div className="aspect-square relative w-[60%] sm:w-full">
                <Image
                  src={item.imageHref}
                  alt={`Image of ${item.name}, ${item.title}`}
                  layout="fill"
                  objectFit="cover"
                  className="bg-primary-100"
                />
              </div>

              <div className="col-span-2">
                <p
                  className={`${
                    item.quote.length < 200
                      ? 'sm:text-3xl text-2xl'
                      : item.quote.length < 300
                      ? 'sm:text-2xl text-xl'
                      : item.quote.length < 400
                      ? 'sm:text-xl text-base'
                      : item.quote.length < 500
                      ? 'sm:text-lg text-sm'
                      : item.quote.length < 900
                      ? 'sm:text-base text-xs'
                      : 'sm:text-sm text-xs'
                  } text-2xl font-semibold`}
                >
                  {`"`}
                  {item.quote}
                  {`"`}
                </p>

                <div className="flex items-end justify-between gap-4 mt-4">
                  <div>
                    <h3 className="text-xl mb-1">{item.name}</h3>
                    <div className="text-base text-gray-500">{item.title}</div>
                  </div>

                  {/* <div>{item.companySVG}</div> */}
                </div>
              </div>
            </li>
          )
        })}
      </ul>

      <div className="flex flex-row gap-8 justify-end">
        <div onClick={handleDecrement} className="p-4 border border-gray-100 border-solid rounded-full">
          <ArrowLeftIcon className="w-5 h-5 text-gray-500" />
        </div>
        <div onClick={handleIncrement} className="p-4 border border-gray-100 border-solid rounded-full">
          <ArrowRightIcon24 className="w-5 h-5 text-gray-500" />
        </div>
      </div>
    </div>
  )
}

const LandingPage = () => {
  const router = useRouter()
  const initialized = useSelector(selectors.getInitialized())
  const isUser = !!useSelector(selectors.getUser())

  const [coachingWays, setCoachingWays] = useState<1 | 2 | 3>(1)

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (!initialized) return
    if (isUser) {
      router.push('/dashboard')
    }
  }, [initialized, isUser])

  return (
    <div>
      <Section className="!py-0">
        <Nav />
      </Section>
      <Section className="items-center" bg="bg-primary-100 relative overflow-hidden">
        <div className="hidden sm:block w-[110vw] h-[110vw] bg-primary-600/5 rounded-full absolute -mt-[30%] pointer-events-none" />
        <div className="hidden sm:block w-[80vw] h-[80vw] bg-primary-600/5 rounded-full absolute -mt-[10%] pointer-events-none" />

        <div className="flex flex-col gap-6 items-start sm:items-center">
          <h1 className="text-4xl sm:text-5xl font-semibold sm:w-[70%] text-left sm:text-center">
            Founding a company is easy; scaling it is hard.
          </h1>
          <p className="text-xl max-w-[480px] text-left mb-0 sm:text-center text-gray-500">
            Top CEOs trust Mochary Method for the practical tools they need to be effective leaders, provide top-notch
            products and services to clients, and lead their industries.
          </p>
          <p className="text-xl max-w-[480px] text-left mb-0 sm:text-center text-gray-500">
            With Mochary Method, tap into{' '}
            <a
              className="text-gray-500 hover:text-gray-600 underline hover:underline underline-offset-4"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.amazon.com/Great-CEO-Within-Tactical-Building-ebook/dp/B07ZLGQZYC"
            >
              The Great CEO Within
            </a>
            .
          </p>
        </div>

        <div className="flex flex-col gap-12 w-full">
          <div className="flex flex-col sm:flex-row gap-3 justify-center">
            <a
              href={CONTACT_LINK}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex justify-center text-lg font-semibold items-center rounded-md border border-transparent bg-primary-600 px-6 py-3 text-white hover:text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
              Request Coaching
            </a>
            <a
              href={CURRICULUM_LINK}
              className="inline-flex justify-center text-lg font-semibold items-center rounded-md border border-solid border-gray-300 bg-white px-6 py-3 text-gray-700 shadow-sm hover:bg-gray-50 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 gap-3"
            >
              <BookOpenIcon className="w-6 h-6" />
              View Curriculum
            </a>
          </div>

          <div className="flex flex-col gap-3 items-center">
            <p className="text-gray-500 text-base">Trusted by CEOs of leading companies, including:</p>

            <div className="flex flex-row items-center justify-center gap-4 sm:gap-8 flex-wrap">
              <div className="h-[30px] flex items-center">
                <img src="/home/Angellist.png" width="133" height="30" style={{ mixBlendMode: 'multiply' }} />
              </div>
              <div className="h-[24px]">
                <img src="/home/OpenAI.svg" width="100" height="24" className="-mt-[18px]" />
              </div>
              <div className="h-[18px]">
                <img src="/home/Coinbase.svg" width="100" height="18" className="-mt-[19px]" />
              </div>
              <div className="h-[30px]">
                <img src="/home/Canva.svg" width="88" height="30" className="-mt-[4px]" />
              </div>
              <div className="h-[20px]">
                <img src="/home/Sequoia.svg" width="143" height="20" className="-mt-[43px]" />
              </div>
              <div className="h-[25px]">
                <img src="/home/Brex.svg" width="95" height="25" className="-mt-[20px]" />
              </div>
              <div className="h-[30px]">
                <img src="/home/Vendr.png" width="120" height="30" />
              </div>
              <div className="h-[30px]">
                <img src="/home/OpenSea.svg" width="131" height="30" className="-mt-[28px]" />
              </div>
            </div>
          </div>
        </div>
      </Section>

      <Section className="!pb-0">
        <div className="flex-col gap-4 hidden sm:flex">
          <h3 className="text-4xl font-bold">Trusted by CEOs of leading tech companies.</h3>
          <p className="text-xl text-gray-500">
            Some of our amazing previous and current clients who’ve adopted our method:
          </p>
        </div>

        <div>
          <Carousel />
        </div>
      </Section>

      <Section>
        <div className="flex flex-col gap-4">
          <p className="text-base text-primary-600 font-semibold">Coaching</p>
          <h3 className="text-3xl sm:text-4xl font-bold sm:max-w-[70%]">
            Matt Mochary and his coaching team are the most sought-after CEO coaches in the industry.
          </h3>
          <p className="text-xl text-gray-500">Here are just a few ways our coaches help our clients:</p>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-16">
          <div>
            <div className="mb-10">
              <div
                className={`px-6 py-4 border-solid border-l-4 ${
                  coachingWays === 1 ? 'border-primary-600' : 'border-primary-50'
                }`}
                onClick={() => setCoachingWays(1)}
                onMouseEnter={() => setCoachingWays(1)}
              >
                <h5 className="text-xl font-semibold mb-2">
                  Implement our{' '}
                  <a
                    className="underline text-black hover:underline hover:text-gray-700 underline-offset-4"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://docs.google.com/document/d/17AfqFdrx0lb6aYb786lY3a-17jUEoxDCRAT-BpDmh2k/edit"
                  >
                    tactical frameworks
                  </a>{' '}
                  for running a great company
                </h5>
                <p className="text-base mb-5 text-gray-500">
                  Implement frameworks for running meetings, 1-1s, giving feedback, setting OKRs, and more.
                </p>
                <a
                  href="https://docs.google.com/document/d/18FiJbYn53fTtPmphfdCKT2TMWH-8Y2L-MLqDk-MFV4s/edit"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600 inline-flex items-center gap-2 font-semibold text-base"
                >
                  Learn more <ArrowRightIcon className="w-5 h-5" />
                </a>
              </div>

              <div
                className={`px-6 py-4 border-solid border-l-4 ${
                  coachingWays === 2 ? 'border-primary-600' : 'border-primary-50'
                }`}
                onClick={() => setCoachingWays(2)}
                onMouseEnter={() => setCoachingWays(2)}
              >
                <h5 className="text-xl font-semibold mb-2">Make better decisions</h5>
                <p className="text-base mb-5 text-gray-500">
                  By creating a space for deep thinking and learning about the different types of decisions and how to
                  make them.
                </p>
                <a
                  href="https://docs.google.com/document/d/1hPdWQjYfdK0SB2i3ViZ9XsMPjTYEi7Do1WI22ofmEdo/edit"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600 inline-flex items-center gap-2 font-semibold text-base"
                >
                  Learn more <ArrowRightIcon className="w-5 h-5" />
                </a>
              </div>

              <div
                className={`px-6 py-4 border-solid border-l-4 ${
                  coachingWays === 3 ? 'border-primary-600' : 'border-primary-50'
                }`}
                onClick={() => setCoachingWays(3)}
                onMouseEnter={() => setCoachingWays(3)}
              >
                <h5 className="text-xl font-semibold mb-2">Deconstruct limiting beliefs</h5>
                <p className="text-base mb-5 text-gray-500">
                  By learning to ask questions, shift out of fear, and process feelings and the stories you tell
                  yourself.
                </p>
                <a
                  href="https://docs.google.com/document/d/1sDWHUm6zwEGiPBQH2Ig6DDvVL-IEQSlfNeQnR2mhjmE/edit"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-600 inline-flex items-center gap-2 font-semibold text-base"
                >
                  Learn more <ArrowRightIcon className="w-5 h-5" />
                </a>
              </div>
            </div>

            <a
              href={COACHES_LINK}
              className="inline-flex text-base font-semibold items-center rounded-md border border-solid border-gray-300 bg-white px-6 py-3 text-gray-700 hover:text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 gap-3"
            >
              Find a coach
            </a>
          </div>

          <div className="bg-primary-50 rounded-[40px] hidden sm:flex items-center justify-center">
            {coachingWays === 1 ? <CoachingWays1Graphic className="-mr-24" /> : null}
            {coachingWays === 2 ? <CoachingWays2Graphic className="-mr-24" /> : null}
            {coachingWays === 3 ? <CoachingWays3Graphic className="-mr-24" /> : null}
          </div>
        </div>
      </Section>

      <Section>
        <div className="flex flex-col gap-4 items-center" id="coaches">
          <h3 className="text-3xl sm:text-4xl font-bold">Meet our team</h3>
          <p className="text-xl text-gray-500 sm:max-w-[60%] text-center">
            Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers you
            to do your best work.
          </p>
        </div>

        <div>
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-x-8 gap-y-16">
            <CoachTeamMember
              imageHref="/home/Celine Teoh.jpeg"
              name="Celine Teoh"
              title="Coach in residence"
              bio="Celine has spent hundreds of hours coaching and advising leaders at seed-stage startups, to venture-backed
              growth companies...."
              profileHref="https://mm-coaches.notion.site/Celine-Teoh-8b99082650d14a0aac06160ad8ef4848"
              emailHref="mailto:celine@mocharymethod.com"
              linkedinHref="https://www.linkedin.com/in/celineteoh/"
              twitterHref="https://twitter.com/celineteoh"
            />
            <CoachTeamMember
              imageHref="/home/Georgia Dienst.jpeg"
              name="Georgia Dienst"
              title="Coach in residence"
              bio="Georgia’s coaching clients have included executives from 21Shares, Cardless, Ramp, K Health and EntrepreneurFirst."
              profileHref="https://mm-coaches.notion.site/Georgia-Dienst-7f46a6dec532402b965e236450f4038c"
              emailHref="mailto:georgia@mocharymethod.com"
              linkedinHref="https://www.linkedin.com/in/georgiadienst/"
              twitterHref="https://twitter.com/georgiadienst"
            />
            <CoachTeamMember
              imageHref="/home/Faith Meyer.jpeg"
              name="Faith Meyer"
              title="Coach in residence"
              bio="Faith’s CEO, executive & investor clients have included OpenAI, Going, Mendel AI, Sword Health, Cubby Beds, Kintsugi, Connext & more."
              profileHref="https://mm-coaches.notion.site/Faith-Meyer-ACC-7be84956b4de4ee2b7c30f441db90dc3"
              emailHref="mailto:faith@mocharymethod.com"
              linkedinHref="https://www.linkedin.com/in/faith-meyer/"
              twitterHref="https://twitter.com/FaithKMeyer"
            />
            <CoachTeamMember
              imageHref="/home/Alexis d'Amecourt.jpeg"
              name="Alexis D’Amecourt"
              title="Head of Coaching"
              bio="Alexis coaches Founders/CEOs, execs, and investors from Opensea, Parallel Learning, Nash, Snapdocs, Luminai, Truework, Treble.ai ..."
              profileHref="https://mm-coaches.notion.site/Alexis-d-Amecourt-c166dfb8021d44428bdbae503d558f50"
              emailHref="mailto:alexis@mocharymethod.com"
              linkedinHref="https://www.linkedin.com/in/alexisdamecourt/"
              twitterHref="https://twitter.com/adamecourt"
            />
            <CoachTeamMember
              imageHref="/home/Matt Mochary.jpeg"
              name="Matt Mochary"
              title="CEO and Coach"
              bio="Matt has coached the leaders of Angelist, Brex, Coinbase, Sequoia, Grammarly, Attentive Mobile, Flexport, Plaid and Reddit."
              profileHref="https://docs.google.com/document/d/1b0SWiIcSCU-AUCEoctK7BO8ybV3MyaH6nR184Um9CTc/edit"
              linkedinHref="https://www.linkedin.com/in/matt-mochary-34bb4/"
              twitterHref="https://twitter.com/mattmochary"
            />
          </div>
        </div>
      </Section>

      <Section className="items-center" bg="bg-primary-50">
        <div className="flex flex-col items-center gap-4">
          <h3 className="text-3xl sm:text-4xl font-bold sm:max-w-[85%] sm:text-center">
            We’re on a mission to make running a fast-scaling company easy by turning all managers into great managers.
          </h3>
          <p className="text-xl text-gray-500">Ready to use the Mochary Method at your company?</p>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href={CONTACT_LINK}
            className="inline-flex text-base font-semibold items-center rounded-md border border-transparent bg-primary-600 px-6 py-3 text-white hover:text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Request Coaching
          </a>
        </div>
      </Section>

      <Section className="!py-8 !sm:pt-16 !sm:pb-12" bg="bg-primary-600">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8">
          <div className="text-white col-span-2 sm:col-span-1">
            <MocharyFullLogoIcon width={100} height={30} color="currentColor" className="!m-0 !mt-[2px]" />
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-sm font-semibold text-primary-300">Product</div>

            <div className="flex flex-col gap-3">
              <a
                href="https://companyos.app/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-base block font-semibold text-primary-200 hover:text-primary-100"
              >
                CompanyOS
              </a>
              <a
                href={CURRICULUM_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base block font-semibold text-primary-200 hover:text-primary-100"
              >
                Curriculum
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-sm font-semibold text-primary-300">Company</div>

            <div className="flex flex-col gap-3">
              <a href={COACHES_LINK} className="text-base block font-semibold text-primary-200 hover:text-primary-100">
                Coaches
              </a>
              <a
                href={CAREERS_LINK}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base block font-semibold text-primary-200 hover:text-primary-100"
              >
                Careers
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="text-sm font-semibold text-primary-300">Legal</div>

            <div className="flex flex-col gap-3">
              <a
                href="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className="text-base block font-semibold text-primary-200 hover:text-primary-100"
              >
                Terms
              </a>
              <a
                href="/privacy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-base block font-semibold text-primary-200 hover:text-primary-100"
              >
                Privacy
              </a>
            </div>
          </div>
        </div>

        <p className="border-t border-solid border-primary-500 text-primary-300 text-base pt-8">
          © 2023 Mochary Method All rights reserved.
        </p>
      </Section>
    </div>
  )
}

export default LandingPage
